module.exports = [{
      plugin: require('../node_modules/gatsby-source-remote-csv/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://docs.google.com/spreadsheets/d/e/2PACX-1vTXfklaECQxv5ghVSr2E3vUF9KZ41SOffV1vu2EInGs1VfsXMHlyZCr0RoO6rGN9XJbjQDdgAs5oYa9/pub?gid=0&single=true&output=csv"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
